import React from "react"

import "./productCard.css"

import { Link }     from "react-router-dom"
import { IProduct } from "interfaces/product/IProduct"
import PriceParser from "misc/priceParser"
import { getProductImageUrl } from "misc/imageUrls"


export default function ProductCard(props: {data: IProduct}){

    return (
        <li id="product-card-main">
            <Link id="product-card-container" 
                to={`/produtos/${props.data.url}?variacao=${props.data.variations[0].name}`}
                state={{ from:"/catalog"}}
                draggable={false}
            >
                <div id="product-card-image-container">
                    <img 
                        id="product-card-image" 
                        src={getProductImageUrl(props.data.variations[0].images[0].filename)} 
                        alt="" 
                        draggable={false}
                        loading="lazy"
                    />
                </div>
                <div id="product-card-name-container">
                    <p id="product-card-name">
                        {`${props.data.name} ${props.data.variations[0].name}`}
                    </p>
                </div>
                <ul id="product-card-size-container">
                    {
                        props.data.variations.map((variation) => {
                            return (
                                <li 
                                    id="product-card-variation2" 
                                    key={variation.id}
                                    style={{backgroundColor: `#${variation.color}`}}
                                />
                            )
                        })
                    }
                </ul>
                <div id="product-card-price-container">
                    {
                        props.data.variations[0].promotionEnabled &&
                        (
                            <div id="product-card-price-promotion">
                                <p id="product-card-price-promotion-old-txt">
                                    R$ {PriceParser.parse(props.data.variations[0].price)}
                                </p>
                                <p id="product-card-price-promotion-new-txt">
                                    R$ {PriceParser.parse(props.data.variations[0].promotionPrice)}
                                </p>
                            </div>
                        )
                    }
                    {
                        !props.data.variations[0].promotionEnabled &&
                        (
                            <div id="product-card-price">
                                <p id="product-card-price-txt">
                                    R$ {PriceParser.parse(props.data.variations[0].price)}
                                </p>
                            </div>
                        )
                    }
                </div>
            </Link>
        </li>
    )
}