import React, {useState, useEffect, useContext} from "react"

import "./promotion.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"
import ProductCard from "components/productCard/productCard"
import { useParams } from "react-router-dom"
import { apiCaller } from "api/apiCaller"

import Background from "assets/backgrounds/category-background-placeholder.svg"
import { AxiosResponse } from "axios"
import { GlobalContext } from "context/globalProvider"
import { IProduct } from "interfaces/product/IProduct"
import IBanner from "interfaces/IBanner"
import { getCoverImageUrl } from "misc/imageUrls"

export default function Promotion(){

    const globalContext = useContext(GlobalContext)

    const [products, setProducts ] = useState<IProduct[]>([])
    const [name,     setName     ] = useState<string>("");
    const [banner,   setBanner   ] = useState<IBanner>();

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("product-category-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])

    const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

    useEffect(() => {

        // apiCaller.get(`/covers/${category.cover_id}`)
        // .then((response: AxiosResponse) => {
        //     const data = response.data.data;
        //     setBanner(data);
        // }).catch((error) => {
            
        // });

        apiCaller
        .post(`/products/find?promotion=${1}`, {
        })
        .then((response: AxiosResponse) => {
            const data: IProduct[] = response.data.data;
            setProducts(data);
        }).catch((error) => {

        });
    }, [globalContext?.categories])


    function getImage() {
        if (banner) {
            return getCoverImageUrl(`${banner.filename}`);
        } else {
            return Background;
        }
    }

    return (
        <>  
            <Header/>
            <div id="product-category-template-main">
                <div id="product-category-template-content">
                    <div id="product-category-template-header">
                        <img 
                            id="product-category-template-header-img"
                            src={getImage()} 
                            alt="" 
                            draggable={false}
                        />
                        <div id="product-category-template-header-title">
                            <div className="product-category-template-header-title-spacer"/>
                            <p id="product-category-template-header-title-txt">
                                Promoções
                            </p>
                            <div className="product-category-template-header-title-spacer"/>
                        </div>
                    </div>
                    <ul id="product-category-template-list" style={{minHeight: screenHeight}}>
                        {
                            products.map((product) => (
                                product.variations.map((variation) => {
                                    return (
                                        <ProductCard
                                            key={product.id + variation.id}
                                            data={{
                                                id: product.id + variation.id,
                                                name: product.name,
                                                url: product.url,
                                                description: product.description,
                                                enabled: product.enabled,
                                                featured: product.featured,
                                                categoryId: product.categoryId,
                                                subcategoryId: product.subcategoryId,
                                                variations: [variation]
                                            }}
                                        />
                                    )
                                })
                            ))
                        }
                    </ul>
                </div>
            </div>
            <Footer/>
        </>
    );
}