import React, {useEffect, useState, createContext, Dispatch } from "react"
import { useNavigate } from "react-router-dom"

import "./productPage.css"

import useQuery from "hooks/useQuery"
import { useParams } from "react-router-dom"
import Header from "components/header/header"
import Footer from "components/footer/footer"
import ProductDetails from "./components/productDetails/productDetails"
import UserMenuNavigationElement from "components/userMenuNavigationElement/userMenuNavigationElement"
import Animation from "./components/animation/animation"
import { IProduct, IProductVariation, ISize} from "interfaces/product/IProduct"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"


interface IProductPageContext {
    buyMode: string
    setBuyMode: Dispatch<React.SetStateAction<string>>
    runAnimation: boolean
    setRunAnimation: Dispatch<React.SetStateAction<boolean>>
    product: IProduct,
    setProduct: Dispatch<React.SetStateAction<IProduct>>
    variation: IProductVariation
    setVariation: Dispatch<React.SetStateAction<IProductVariation>>
    size: ISize
    setSize: Dispatch<React.SetStateAction<ISize>>
}

export const ProductPageContext = createContext<IProductPageContext | undefined>(undefined);

export default function ProductPage(props: {}){

    const navigate = useNavigate()

    const params = useParams()
    const query  = useQuery()
    
    const [pathList, setPathList] = useState<string[]>([])
    const [buyMode, setBuyMode] = useState<string>("")
    const [runAnimation, setRunAnimation] = useState<boolean>(false)
    const [product, setProduct] = useState<IProduct>(
        {
            id: "",
            name: "",
            url: "",
            enabled: false,
            featured: false,
            categoryId: "",
            subcategoryId: "",
            variations: [],
            description: ""
        }
    )
    const [variation, setVariation] = useState<IProductVariation>(
        {
            id: "",
            name: "",
            sku: "",
            color: "",
            price: 0,
            enabled: false,
            promotionEnabled: false,
            promotionPrice: 0,
            sizes: [],
            images: []
        }
    )
    const [size, setSize] = useState<ISize>(
        {
            id: "",
            size: "",
            quantity: 0,
            enabled: false
        }
    )
    
    useEffect(() => {
        parsePath(`/${params.url}`)
        var antes = Date.now();
        apiCaller.post("/products/find",
            {
                enabled: true
            },
            {
                params: {
                    url: params.url
                }
            }
        ).then((response: AxiosResponse) => {
            const variationParameter = query.get("variacao")
            setProduct(response.data.data[0])
            
            if (variationParameter){

                const responseProduct = response.data.data[0]

                const selectedVariation:IProductVariation = responseProduct.variations.find((variation: IProductVariation) => {
                    if (variation.name === variationParameter){
                        return variation
                    }
                })

                setVariation(selectedVariation)
                setSize(selectedVariation.sizes[0])
            } else {
                setVariation(response.data.data[0].variations[0])
                setSize(response.data.data[0].variations[0].sizes[0])
            }
           
        }).catch((error: AxiosError) => {
            navigate("/")
        })
    },[])

    function parsePath(path: string){
        const splitedPath = path.split("/")

        const list = []
        let fullPath = ""

        for (let index = 0; index < splitedPath.length; index++) {
            if (index !== 0) {
                fullPath = fullPath + "/" + splitedPath[index]
                list.push(fullPath)            
            } else {
                list.push("/") 
            }
        }
        setPathList(list)
    }

    return (
        <ProductPageContext.Provider value={{
                buyMode: buyMode,
                setBuyMode: setBuyMode,
                runAnimation: runAnimation,
                setRunAnimation: setRunAnimation,
                product: product,
                setProduct: setProduct,
                variation: variation,
                setVariation: setVariation,
                size: size,
                setSize: setSize
            }}
        >
            <Header/>
            <div id="product-page-main">
                <div id="product-page-content">
                    <div id="product-page-header">
                        <ul id="product-page-header-navigation-container">
                            {
                                pathList.map((path, index) => {
                                    if (path === pathList[pathList.length-1]){
                                        return (
                                            <UserMenuNavigationElement 
                                                key={index}
                                                data={{url: path, last: true, prefix: "/produtos"}}
                                            />
                                        )
                                    } else {
                                        return (
                                            <UserMenuNavigationElement 
                                                key={index}
                                                data={{url: path, last: false}}
                                            />
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div id="product-page-body">
                        <div id="product-page-body-details">
                            <ProductDetails data={product}/>
                        </div>
                        <div id="product-page-body-description">
                            <p id="product-page-body-description-txt">
                                {product.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Animation/>
        </ProductPageContext.Provider>
    )
}